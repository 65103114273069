/** @format */

import React, { useEffect } from "react";
import NavBar from "../../components/landingWebsiteComponents/NavBar";
import Banner from "../../components/landingWebsiteComponents/Banner";
import Footer from "../../components/landingWebsiteComponents/Footer";
import BookingTab from "../../components/landingWebsiteComponents/BookingTab";
import { useDispatch } from "react-redux";
import { tabServices } from "../../action/tabActions";
import choos4 from "../../assets/images/choos-icon-4.png";
import choos2 from "../../assets/images/choos-icon-2.png";
import choos3 from "../../assets/images/choos-icon-3.png";
import choos7 from "../../assets/images/choos-icon-7.png";
import choos8 from "../../assets/images/choos-icon-8.png";
import choos6 from "../../assets/images/choos-icon-6.png";
import choos9 from "../../assets/images/choos-icon-9.png";
// import ser2 from "../../assets/images/ser-2.png";
// import ser3 from "../../assets/images/ser-3.png";
// import underConstruction from "../../assets/images/undraw_under_construction_46pa.png";

const Services = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(tabServices());
  }, []);
  return (
    <>
      <NavBar />
      <Banner name="Services" />
      <div className="abt-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="sertext text-center">
                <h4>Complete Medical Transportation</h4>
                <p>
                  At Connect Care Trans, we’re determined to make a difference
                  in non-medical transportation. Never again worry about getting
                  to your medical appointment on time. We've made it our job to
                  take care of these concerns. Our constant goal is to deliver
                  exceptional service.
                </p>
                <p>
                  We offer a complete approach to your healthcare. Our team
                  understands getting to and from medical appointments can be as
                  important as the appointment. Our experienced staff and custom
                  fleet of vehicles are equipped to handle just about any
                  situation. You get tailored transport options to meet your
                  specific requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="serv-section">
        <div className="container">
          <div className="row ">
            <div className="col-md-12 mb-3">
              <h4 className="text-center">Choose Connect Care Trans</h4>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card mb-3">
                <div className="iconimg">
                  <img src={choos4} alt="" className="card-img-top rounded-0" />
                </div>
                <div className="card-body">
                  <div className="cardtext text-center">
                    <h4>We Get There On Time</h4>
                    <p>
                      We understand medical appointments can be stressful. 
                      That's why we dedicate ourselves on-time arrivals, every
                      time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 ">
              <div className="card mb-3">
                <div className="iconimg">
                  <img src={choos4} alt="" className="card-img-top rounded-0" />
                </div>
                <div className="card-body">
                  <div className="cardtext text-center">
                    <h4>True Door-Through-Door Service</h4>
                    <p>
                      We go above and beyond! Our trained staff is here to
                      assist you every step of the way, from your living room to
                      your destination and back. Whether it’s navigating stairs,
                      carrying personal belongings, or ensuring a safe and
                      secure arrival, we’re dedicated to making your journey
                      smooth and worry-free.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mt-md-30">
              <div className="card mb-3">
                <div className="iconimg">
                  <img src={choos3} alt="" className="card-img-top rounded-0" />
                </div>
                <div className="card-body">
                  <div className="cardtext text-center">
                    <h4>Accessibility for All</h4>
                    <p>
                      We have accessible vehicles to meet your needs. Our
                      professional staff are trained to provide the assistance
                      you need.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-12 mt-md-30"
              style={{ marginTop: "-170px" }}
            >
              <div className="card mb-3">
                <div className="iconimg">
                  <img src={choos6} alt="" className="card-img-top rounded-0" />
                </div>
                <div className="card-body">
                  <div className="cardtext text-center">
                    <h4>Family Friendly Policy</h4>
                    <p>
                      We believe in the power of support. That's why we allow
                      family members or caregivers to travel with you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-12 "
              style={{ marginTop: "10px" }}
            >
              <div className="card mb-3">
                <div className="iconimg">
                  <img src={choos7} alt="" className="card-img-top rounded-0" />
                </div>
                <div className="card-body">
                  <div className="cardtext text-center">
                    <h4>Ready to Serve 24/7</h4>
                    <p>
                      Health doesn't follow a 9 to 5 schedule and neither do we.
                      Our services are available around the clock to help
                      anytime you need us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-12"
              style={{ marginTop: "-165px" }}
            >
              <div className="card mb-3">
                <div className="iconimg">
                  <img src={choos9} alt="" className="card-img-top rounded-0" />
                </div>
                <div className="card-body">
                  <div className="cardtext text-center">
                    <h4>We Get There On Time</h4>
                    <p>
                      Looking for a quick and efficient option? Our curb-to-curb
                      service ensures a seamless transition from the curb to
                      your appointment or activity, making your journey simple
                      and straightforward.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-12 "
              style={{ marginTop: "-200px" }}
            >
              <div className="card mb-3">
                <div className="iconimg">
                  <img src={choos8} alt="" className="card-img-top rounded-0" />
                </div>
                <div className="card-body">
                  <div className="cardtext text-center">
                    <h4>Door-to-Door Convenience</h4>
                    <p>
                      Enjoy a hassle-free experience with our door-to-door
                      service. We’ll pick you up from your doorstep and ensure
                      you’re safely escorted to the entrance of your
                      destination, providing peace of mind and comfort every
                      step of the way.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 "></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Services;
