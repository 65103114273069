import React, { useEffect } from "react";
import NavBar from "../../components/landingWebsiteComponents/NavBar";
import Banner from "../../components/landingWebsiteComponents/Banner";
import Footer from "../../components/landingWebsiteComponents/Footer";
import { useDispatch } from "react-redux";
import AboutandServices from "../../components/landingWebsiteComponents/AboutandServices";
import BookingTab from "../../components/landingWebsiteComponents/BookingTab";
import { tabWhyUs } from "../../action/tabActions";
import aboutus from "../../assets/images/abtimg.png";
import aboutus1 from "../../assets/images/abt-icon-1.png";
import aboutus2 from "../../assets/images/abt-icon-2.png";
import aboutus3 from "../../assets/images/abt-icon-3.png";
import { Link } from "react-router-dom";

const WhyUs = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(tabWhyUs());
  }, []);
  return (
    <>
      <NavBar />
      <Banner name="About Us" />
      <div className="abt-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-12">
              <div className="abtwel-text">
                <p>
                  Welcome to Connect Care Trans. We're a provider of
                  non-emergency medical transportation, where we work to improve
                  the way people get healthcare by offering personalized
                  transportation services. We make sure no one misses a medical
                  appointment because of unreliable transportation.{" "}
                </p>
                <p>
                  We understand you have your own needs and concerns. That's why
                  we have simplified getting to healthcare appointments. we're
                  committed to providing a stress free day.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 col-sm-6"
              style={{ textAlign: "start" }}
            >
              <div>
                <h5>About</h5>
                <h4>Connect Care Trans</h4>
                <p>
                  Connect Care Trans is an important part of healthcare. We are
                  a locally owned Chicago Non-Emergency Medical Transportation
                  (NEMT) company. We take pride in our deep roots in this
                  community.
                </p>
                <p>
                  Our story began with a simple observation. Too many people
                  were missing vital medical appointments because of unreliable
                  transportation. Our founders drew from their extensive
                  experience as public chauffeurs. They saw an opportunity to
                  make a difference. They combined their expertise in
                  professional transportation with a passion for patient care to
                  create Connect Care Trans.
                </p>
                <p>
                  Today, our staff continues this mission. Rather than just
                  being drivers, we're caregivers and companions. Our staff has
                  detailed training to ensure they can handle any situation with
                  professionalism and compassion.
                </p>
                <p>
                  Whether you need transportation to a routine check-up, a
                  specialized treatment, or a hospital discharge, can get you
                  there and home again. We offer door through door service to
                  take you from your home to the doctor's office and back again.
                </p>
                <p>
                  Don't just take our word for it. Our clients often tell us
                  we're the best kept secret in medical transportation. We
                  invite you to experience the Connect Care Trans difference for
                  yourself. Contact our reservation team and learn why so many
                  Chicagoans trust us with medical transportation.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="mt-3">
                <img src={aboutus} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AboutandServices aboutUsPage /> */}
      <div className="serv-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="text-center">
                <h2>Our Commitment To You</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="cardbg h-100">
                <div className="iconimg">
                  <img src={aboutus1} alt="" />
                </div>
                <div className="cardbgbody text-center">
                  <h3>Safety</h3>
                  <p className="text-white">
                    Safety isn't just a priority, it's at the root of all we do.
                    From our expertly maintained vehicles to well trained staff.
                    All parts of the operation are optimized for your safety.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="cardbg h-100">
                <div className="iconimg">
                  <img src={aboutus2} alt="" />
                </div>
                <div className="cardbgbody text-center">
                  <h3>Reliability</h3>
                  <p className="text-white">
                    We understand the importance of your medical appointments.
                    That's why we guarantee punctual arrivals. We offer this
                    industry's lowest return trip wait times. You'll never have
                    to worry about transportation again.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="cardbg h-100">
                <div className="iconimg">
                  <img src={aboutus3} alt="" />
                </div>
                <div className="cardbgbody text-center">
                  <h3>Professionalism</h3>
                  <p className="text-white">
                    Professionalism is at our core. We believe everyone deserves
                    to be treated with dignity and respect. Our staff offers
                    compassionate and professional care that makes you feel like
                    family.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="abt-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-12 mb-3">
              <div className="text-center">
                <h2>
                  Ready to Experience the Difference of Connect Care Trans?
                </h2>
              </div>
            </div>
            <div className="col-lg-10 col-md-12">
              <div className="list-text">
                <ul style={{ textAlign: "start" }}>
                  <li>
                    <i class="f-8 fa-solid fa fa-circle"></i>&nbsp;&nbsp;&nbsp;
                    Scheduling your ride with us is simple. With just one phone
                    call, you can be at your appointment or wherever you desire.
                    Our friendly team is standing by to help you.
                  </li>
                  <li>
                    <i class="f-8 fa-solid fa fa-circle"></i>&nbsp;&nbsp;&nbsp;
                    We're your partner in health and wellness. Let us take the
                    worry out of medical transportation. So you can focus on
                    what really matters - your health.
                  </li>
                  <li>
                    <i class="f-8 fa-solid fa fa-circle"></i>&nbsp;&nbsp;&nbsp;
                    Contact us today and we’ll have a friendly, reliable,
                    professional driver at your doorstep – every time. That's
                    the Connect Care Trans promise to you.
                  </li>
                </ul>
              </div>
              {/* <BookingTab /> */}
              <div className="text-center">
                <Link to="/contact-us" className="btn btn-secondary rounded-1">
                  Contact Us today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default WhyUs;
