import React from "react";

const PrivacyPolicyModal = ({ showDropDown, openDropDown }) => {
  return (
    <>
      <div
        // className="modal fade show"
        className={`modal fade ${showDropDown ? "show" : ""}`}
        id="PrivacypolicyModal"
        tabindex="-1"
        aria-labelledby="PrivacypolicyModalLabel"
        aria-hidden="true"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-lg  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="PrivacypolicyModalLabel">
                Privacy Policy Requirements
              </h5>
              <button
                type="button"
                // className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ backgroundColor: "white" }}
                onClick={() => openDropDown()}
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p>Your privacy policy must include:</p>
              <ul>
                <li>
                  <strong>
                    <i class="f-8 fa-solid fa fa-circle"></i>&nbsp;&nbsp;Data
                    Collection:{" "}
                  </strong>
                  What personal information is collected and how?
                </li>
                <li>
                  <strong>
                    <i class="f-8 fa-solid fa fa-circle"></i>&nbsp;&nbsp;Data
                    Usage:{" "}
                  </strong>
                  How the information will be used.
                </li>
                <li>
                  <strong>
                    <i class="f-8 fa-solid fa fa-circle"></i>&nbsp;&nbsp;Data
                    Protection:{" "}
                  </strong>
                  Measures taken to protect the data.
                </li>
                <li>
                  <strong>
                    <i class="f-8 fa-solid fa fa-circle"></i>&nbsp;&nbsp;User
                    Rights:{" "}
                  </strong>
                  Rights regarding their personal data and how to exercise them.
                </li>
                <li>
                  <strong>
                    <i class="f-8 fa-solid fa fa-circle"></i>&nbsp;&nbsp;Opt-In
                    and Opt-Out:{" "}
                  </strong>
                  Details about how users can opt-in and opt-out.
                </li>
                <li>
                  <strong>
                    <i class="f-8 fa-solid fa fa-circle"></i>&nbsp;&nbsp;Contact
                    Information:{" "}
                  </strong>
                  For privacy-related inquiries.
                </li>
                <li>
                  <strong>
                    <i class="f-8 fa-solid fa fa-circle"></i>&nbsp;&nbsp;Policy
                    Updates:{" "}
                  </strong>
                  How users will be informed about changes.
                </li>
                <li>
                  <strong>
                    <i class="f-8 fa-solid fa fa-circle"></i>
                    &nbsp;&nbsp;Accessibility:{" "}
                  </strong>
                  Ensure the policy is easily accessible.
                </li>
                <li>
                  <i class="f-8 fa-solid fa fa-circle"></i>&nbsp;&nbsp;SMS
                  consent and phone numbers will not be shared with third
                  parties or affiliates for marketing purposes.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrivacyPolicyModal;
