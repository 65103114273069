/** @format */

import React, { useEffect, useState } from "react";
import NavBar from "../../components/landingWebsiteComponents/NavBar";
import Banner from "../../components/landingWebsiteComponents/Banner";
import Footer from "../../components/landingWebsiteComponents/Footer";
import { tabContactUs } from "../../action/tabActions";
import { useDispatch } from "react-redux";
import ContactUsFormComponent from "../../components/landingWebsiteComponents/websiteContactusComponents/ContactUsFormComponent";
import { createContactUs } from "../../action/contactusActions";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import PrivacyPolicyModal from "./PrivacyPolicyModal";

const ContactUs = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(tabContactUs());
  }, []);
  const [values, setValues] = useState({
    // id: '',
    // name: "",
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    // subjectForm: "",
    // companyName: "",
    // phone: "",
    messageConsent: false,
    // date: '',
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const contactusData = {
    // name: values.name,
    firstName: values.firstName,
    lastName: values.lastName,
    companyName: values.companyName,
    email: values.email,
    subjectForm: values.subjectForm,
    phone: values.phone,
    message: values.message,
    messageConsent: values.messageConsent,
  };
  console.log(contactusData, "contactusData");
  const handlePhone = (fieldName) => (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    let formattedValue = "";
    if (numericValue.length > 0) {
      formattedValue = `(${numericValue.slice(0, 3)}`;
    }
    if (numericValue.length > 3) {
      formattedValue += `)${numericValue.slice(3, 6)}`;
    }
    if (numericValue.length > 6) {
      formattedValue += `-${numericValue.slice(6, 10)}`;
    }
    setValues({
      ...values,
      [fieldName]: formattedValue,
    });
  };

  const [isVerified, setIsVerified] = useState(false);

  const handleRecaptchaChange = (value) => {
    if (value) {
      setIsVerified(true); // User passed reCAPTCHA
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(createContactUs(contactusData, setValues));
  };
  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  return (
    <>
      <NavBar />
      <Banner name="Contact Us" />
      {/* <ContactUsFormComponent /> */}
      <div className="abt-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 mb-3">
              <h3>Get in touch with us!</h3>
            </div>
            <div className="col-lg-6 mb-3">
              <form
                className="p-2 p-sm-4 contact-form  shadow"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      name="name"
                      placeholder="First Name *"
                      className="form-control form-control-lg"
                      value={values.firstName}
                      required
                      onChange={handleChange("firstName")}
                      maxLength={35}
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      name="name"
                      placeholder="Last Name *"
                      className="form-control form-control-lg"
                      value={values.lastName}
                      required
                      onChange={handleChange("lastName")}
                      maxLength={35}
                    />
                  </div>

                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email *"
                      className="form-control form-control-lg"
                      value={values.email}
                      required
                      onChange={handleChange("email")}
                    />
                  </div>
                  <div className="col-12 col-md-6  mb-3">
                    <input
                      type="phone"
                      name="phone"
                      placeholder="Your Phone (Optional)"
                      className="form-control form-control-lg"
                      value={values.phone}
                      onChange={handlePhone("phone")}
                    />
                  </div>

                  <div className="col-12  mb-3">
                    <textarea
                      placeholder="Message"
                      name="message"
                      rows="5"
                      className="form-control form-control-lg"
                    ></textarea>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input bg-gray"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                      />
                      <label
                        className="form-check-label"
                        for="inlineCheckbox1"
                        style={{ textAlign: "start" }}
                      >
                        By checking this box, you agree to receive SMS messages
                        from{" "}
                        <strong style={{ color: "#001C3D" }}>
                          Connect Care Trans,
                        </strong>{" "}
                        you can reply STOP to opt-out at any time, this is{" "}
                        <button
                          type="button"
                          // href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#PrivacypolicyModal"
                          className="text-decoration-none"
                          style={{ backgroundColor: "#dcdcdc" }}
                        >
                          <strong
                            onClick={() => {
                              openDropDown();
                            }}
                          >
                            Privacy Policy
                          </strong>
                        </button>
                      </label>
                    </div>
                    <ReCAPTCHA
                      sitekey="6LeYbncqAAAAAA8-sdp7cj5bwH8QmMw-N8q14dsv"
                      onChange={handleRecaptchaChange}
                    />
                  </div>
                  <div
                    className="col-12 text-end"
                    style={{ justifyContent: "end", display: "flex" }}
                  >
                    <button
                      className="btn btn-secondary px-5 rounded-2"
                      type="submit"
                    >
                      {" "}
                      Send Yours Message{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-4">
              {/* <iframe className="w-100 rounded-2 shadow" 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.580774591111!2d-87.79388052388157!3d41.98780985853801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fc94354c78d17%3A0x8b9c5a102c0b3730!2s5858%20N%20Northwest%20Hwy%20g%2C%20Chicago%2C%20IL%2060631%2C%20USA!5e0!3m2!1sen!2s!4v1713776164566!5m2!1sen!2s" height=480" style={{border:"0"}}, 
              allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
              </iframe> */}
              <iframe
                className="w-100 rounded-2 shadow"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.580774591111!2d-87.79388052388157!3d41.98780985853801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fc94354c78d17%3A0x8b9c5a102c0b3730!2s5858%20N%20Northwest%20Hwy%20g%2C%20Chicago%2C%20IL%2060631%2C%20USA!5e0!3m2!1sen!2s!4v1713776164566!5m2!1sen!2s"
                height="550"
                style={{ border: "0" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="conttext-section">
        <div className="container contact-section">
          <div className="row justify-content-center ">
            <div className="col-md-10">
              <h3 className="py-3">Get in touch with us!</h3>
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <form
                className="p-2 p-sm-4 contact-form w-100 h-100 shadow"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-12 col-md-6">
                    <input
                      type="text"
                      name="name"
                      placeholder="First Name *"
                      className="input w-100 py-2 px-3"
                      value={values.firstName}
                      required
                      onChange={handleChange("firstName")}
                      maxLength={35}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="text"
                      name="name"
                      placeholder="Last Name *"
                      className="input w-100 py-2 px-3"
                      value={values.lastName}
                      required
                      onChange={handleChange("lastName")}
                      maxLength={35}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email *"
                      className="input w-100 py-2 px-3"
                      required
                      value={values.email}
                      onChange={handleChange("email")}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="phone"
                      name="phone"
                      placeholder="Your Phone (Optional)"
                      className="input w-100 py-2 px-3"
                      value={values.phone}
                      onChange={handlePhone("phone")}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="text"
                      name="subject"
                      value={values.subjectForm}
                      onChange={handleChange("subjectForm")}
                      placeholder="Subject (Optional)"
                      className="input w-100 py-2 px-3"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="text"
                      name="name"
                      placeholder="Company Name (Optional)"
                      className="input w-100 py-2 px-3"
                      value={values.companyName}
                      onChange={handleChange("companyName")}
                    />
                  </div>

                  <div className="col-12">
                    <textarea
                      placeholder="Your Message"
                      name="message"
                      rows="5"
                      className="input w-100 py-2 px-3"
                      value={values.message}
                      required
                      onChange={handleChange("message")}
                    ></textarea>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div>
                      <label
                        className="bokinglabel"
                        for="flexSwitchCheckDefault"
                      >
                        <input
                          type="checkbox"
                          checked={values.messageConsent}
                          onChange={() =>
                            setValues((prevValues) => ({
                              ...prevValues,
                              messageConsent: !prevValues.messageConsent,
                            }))
                          }
                        />
                        &nbsp;By Checking this box you agree to receive text
                        messages from Connect Care Trans, you can reply to
                        opt-out at any time, this is my
                        <Link to="/privacy-policy"> privacy policy</Link>.
                      </label>
                    </div>
                    <div className="">
                      <ReCAPTCHA
                        sitekey="6LeYbncqAAAAAA8-sdp7cj5bwH8QmMw-N8q14dsv"
                        onChange={handleRecaptchaChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <button
                      className="btn btn-primaryy w-100 p-2 fs-5 text-light"
                      type="submit"
                      // onClick={handleSubmit}
                    >
                      Send Your Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-12 col-lg-4 mb-3">
              <div className="cont-text">
                <h4>Get Info</h4>
                <div className="contentpage">
                  <ul>
                    <li>
                      <i className="fa fa-phone" id="con-icon"></i>
                      <a href="#">(773)340-4373</a>
                    </li>
                    <li>
                      <i className="fa fa-envelope" id="con-icon"></i>
                      <a href="#">Info@connectcaretrans.com</a>
                    </li>
                    <li>
                      <i className="fa fa-globe" id="con-icon"></i>
                      <a href="#">www.connectcaretrans.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <PrivacyPolicyModal
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
      <Footer />
    </>
  );
};
export default ContactUs;
