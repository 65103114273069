/** @format */

// BACKEND SERVER
// export const SERVERADDRESS = "http://localhost:5000";
// export const SOCKET_SERVER_ADDRESS = "http://localhost:5000";
// // export const SERVERADDRESS = "/api";
export const SERVERADDRESS =
  "https://connectcaretrans-test-backend-a7e4da8d52da.herokuapp.com";
// export const SERVERADDRESS =
//   "https://connectcaretrans-backend-8a7b18191277.herokuapp.com";
// export const SOCKET_SERVER_ADDRESS =
//   "https://connectcaretrans-test-sockets-4a091d4e06bd.herokuapp.com";

// env options
// local or production
export const ENVIRONMENT = "local"; //
// export const BUCKET_BASE_URL =
//   "https://pub-7e87be927e7b48cc8c26e1656ea439fe.r2.dev/";

// export const BUCKET_ENDPOINT =
//   "https://5aa5d988e1bde0d278ab9f851dccfa85.r2.cloudflarestorage.com";
// export const BUCKET_NAME = "cctportal";
// export const BUCKET_REGION = "auto";
// export const BUCKET_ACCESS_KEY_ID = "b66fb2c2c143b626a436ec1caa808431";
// export const BUCKET_SECRET_KEY =
//   "7b0b2f0e36f69ed390c425f173c334dab1ef8b0d4fa7f193555f059febf91cea";

// CONTACT US CONST
export const GET_CONTACTUS_REQUEST = "GET_CONTACTUS_REQUEST";
export const GET_CONTACTUS_SUCCESS = "GET_CONTACTUS_SUCCESS";
export const GET_CONTACTUS_FAIL = "GET_CONTACTUS_FAIL";
export const GET_CONTACTUS_BY_ID_REQUEST = "GET_CONTACTUS_BY_ID_REQUEST";
export const GET_CONTACTUS_BY_ID_SUCCESS = "GET_CONTACTUS_BY_ID_SUCCESS";
export const GET_CONTACTUS_BY_ID_FAIL = "GET_CONTACTUS_BY_ID_FAIL";
export const CREATE_CONTACTUS_MSG_REQUEST = "CREATE_CONTACTUS_MSG_REQUEST";
export const CREATE_CONTACTUS_MSG_SUCCESS = "CREATE_CONTACTUS_MSG_SUCCESS";
export const CREATE_CONTACTUS_MSG_FAIL = "CREATE_CONTACTUS_MSG_FAIL";
// Chat Constants
export const CHAT_CREATE_REQUEST = "CHAT_CREATE_REQUEST";
export const CHAT_CREATE_SUCCESS = "CHAT_CREATE_SUCCESS";
export const CHAT_CREATE_FAIL = "CHAT_CREATE_FAIL";

export const GROUP_CREATE_REQUEST = "GROUP_CREATE_REQUEST";
export const GROUP_CREATE_SUCCESS = "GROUP_CREATE_SUCCESS";
export const GROUP_CREATE_FAIL = "GROUP_CREATE_FAIL";

export const CHATS_GET_REQUEST = "CHATS_GET_REQUEST";
export const CHATS_GET_SUCCESS = "CHATS_GET_SUCCESS";
export const CHATS_GET_FAIL = "CHATS_GET_FAIL";

export const READ_CHAT_REQUEST = "READ_CHAT_REQUEST";
export const READ_CHAT_SUCCESS = "READ_CHAT_SUCCESS";
export const READ_CHAT_FAIL = "READ_CHAT_FAIL";

export const CHAT_SELECT_REQUEST = "CHAT_SELECT_REQUEST";
export const CHAT_SELECT_SUCCESS = "CHAT_SELECT_SUCCESS";
export const CHAT_SELECT_FAIL = "CHAT_SELECT_FAIL";
export const MODIFY_SELECT_SUCCESS = "MODIFY_SELECT_SUCCESS";
export const MODIFY_SELECT_ACCEPTED = "MODIFY_SELECT_ACCEPTED";

export const MESSAGE_GET_REQUEST = "MESSAGE_GET_REQUEST";
export const MESSAGE_GET_SUCCESS = "MESSAGE_GET_SUCCESS";
export const MESSAGE_GET_FAIL = "MESSAGE_GET_FAIL";

export const MESSAGE_SEND_REQUEST = "MESSAGE_SEND_REQUEST";
export const MESSAGE_SEND_SUCCESS = "MESSAGE_SEND_SUCCESS";
export const MESSAGE_SEND_FAIL = "MESSAGE_SEND_FAIL";

export const MESSAGE_RECEIVE_SUCCESS = "MESSAGE_RECEIVE_SUCCESS";

export const READ_MESSAGES_REQUEST = "READ_MESSAGES_REQUEST";
export const READ_MESSAGES_SUCCESS = "READ_MESSAGES_SUCCESS";
export const READ_MESSAGES_FAIL = "READ_MESSAGES_FAIL";

export const CALL_SEND_REQUEST = "CALL_SEND_REQUEST";
export const CALL_SEND_SUCCESS = "CALL_SEND_SUCCESS";
export const CALL_SEND_FAIL = "CALL_SEND_FAIL";

export const CALL_RECEIVED = "CALL_RECEIVED";
export const CALL_DECLINED = "CALL_DECLINED";
export const POPUP_OFF = "POPUP_OFF";
export const POPUP_ON = "POPUP_ON";

// EXTERNAL COMPANY
export const COMPANY_CREATE_REQUEST = "COMPANY_CREATE_REQUEST";
export const COMPANY_CREATE_SUCCESS = "COMPANY_CREATE_SUCCESS";
export const COMPANY_CREATE_FAIL = "COMPANY_CREATE_FAIL";
