/** @format */

import React, { useEffect, useState } from "react";
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindowF,
  //   Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";

const containerStyle = {
  width: "100%",
  height: "220px",
};

const MapComponent = ({ stopArr }) => {
  //   const reservation = useSelector((state) => state.reservation);
  const [center, setCenter] = useState({
    lat: 41.871109784505364,
    lng: -87.63314220827407,
  });
  let distanceFromPickToDropOff = 0;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC653TecaJSucmBmqXPr9twuP_jG55NACA",
  });

  const directionsService = new window.google.maps.DirectionsService();
  const directionsRenderer = new window.google.maps.DirectionsRenderer();
  // console.log(map)
  const [map, setMap] = React.useState(null);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  // directionsRenderer.setMap(map);
  // const [dir, setDir] = useState()
  // const [dir2, setDir2] = useState()
  const [dir3, setDir3] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [totalMi, setTotalMi] = useState(0);
  //   const newArr = reservation?.markedLocation?.reservation?.rideHistory;
  const findDirections = async () => {
    await directionsService.route(
      {
        origin: {
          query: stopArr[0]?.address?.address,
        },
        destination: {
          query: stopArr[1]?.address?.address,
        },
        // origin: {lat: newArr[i].address.latitude, lng: newArr[i].address.longitude},
        // destination: {lat: newArr[i+1].address.latitude, lng: newArr[i+1].address.longitude},
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        console.log("response", result);

        if (status === window.google.maps.DirectionsStatus.OK) {
          // setDir3([...dir3, result]);
          setDir3([result]);
          directionsRenderer.setDirections(result);
          setTotalMi(result.routes[0].legs[0].distance.text);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
    setShowMap(true);
  };
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    console.log(stopArr);
    if (stopArr[0]?.address?.latitude && stopArr[1]?.address?.latitude) {
      findDirections();
      setDisplay(true);
    } else {
      setDisplay(false);
      setDir3([]);
    }
  }, [stopArr]);
  console.log(dir3, "diir3");
  console.log(
    dir3.length > 0 && dir3[0].routes[0].legs[0].start_location.lat(),
    "diir3 new"
  );

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        // center={{
        //   lat: reservation?.markedLocation?.reservation?.rideHistory[0].address
        //     .latitude,
        //   lng: reservation?.markedLocation?.reservation?.rideHistory[0].address
        //     .longitude,
        // }}
        center={center}
        zoom={12}
        onUnmount={onUnmount}
        // onClick={_onClick}
      >
        {display &&
          dir3 &&
          dir3.length > 0 &&
          dir3.map((stop, i) => {
            return <DirectionsRenderer key={i} directions={stop} />;
          })}
        {/* {display &&
          dir3.length > 0 &&
          <InfoWindowF position={{
            lat: dir3[0].routes[0].legs[0].start_location.lat(),
            lng: dir3[0].routes[0].legs[0].start_location.lng(),
          }}>
            <div>
              <p>{totalMi}</p>
            </div>
          </InfoWindowF>} */}
      </GoogleMap>
      {display && (
        <div>
          <strong>Total Mileage: {totalMi}</strong>
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

export default MapComponent;
