/** @format */

import React, { useState } from "react";
import HomeMainComponent from "../../src/components/landingWebsiteComponents/websiteHomeComponents/HomeMainComponent";
// import { tabHome } from '../../actions/tabActions'
import AboutandServices from "../../src/components/landingWebsiteComponents/AboutandServices";
import BookingTab from "../../src/components/landingWebsiteComponents/BookingTab";
// import logo1 from "../assets/images/logo-6.png";
import logo1 from "../assets/images/logo-1.png";
import logo2 from "../assets/images/logo-2.png";
import icon1 from "../assets/images/icon-1.png";
import icon2 from "../assets/images/icon-2.png";
import icon3 from "../assets/images/icon-3.png";
// import logo1 from "../"
import NavBar from "../components/landingWebsiteComponents/NavBar";
import Footer from "../components/landingWebsiteComponents/Footer";
import { Link } from "react-router-dom";

const Home = () => {
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(tabHome())
  // }, [])
  const reviews = [
    {
      name: "Angelina Adame",
      avatar:
        "https://ui-avatars.com/api/?name=A&color=ffffff&background=F4511D",
      text: "Connect Care has transported our residents for months now, and I can only say good things about them. They are always timely and communicate extremely well. I can't thank Abdi enough, he is extremely kind and truly goes above and beyond. I have worked with a lot of transportation companies, and connect care is one of the best. Love working with them and they always make my job easier! Thank you a million.",
    },
    {
      name: "Linda P McClain",
      avatar:
        "https://ui-avatars.com/api/?name=L&color=ffffff&background=512DA8",
      text: "They are always professional and truly caring. I have the utmost respect for their service. I have had 10 round trips so far and all were completed flawlessly.",
    },
    {
      name: "Sherryl Cadang",
      avatar:
        "https://ui-avatars.com/api/?name=S&color=ffffff&background=512DA8",
      text: "Good service",
    },
  ];

  // const [currentIndex, setCurrentIndex] = useState(0);

  // const handleNext = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex + 2) % reviews.length);
  // };

  // const handlePrev = () => {
  //   setCurrentIndex(
  //     (prevIndex) => (prevIndex - 2 + reviews.length) % reviews.length
  //   );
  // };

  // const getVisibleReviews = () => {
  //   return [
  //     reviews[currentIndex],
  //     reviews[(currentIndex + 1) % reviews.length],
  //   ];
  // };

  // const visibleReviews = getVisibleReviews();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(-1); // To track which review is expanded (-1 means none)

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 2) % reviews.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 2 + reviews.length) % reviews.length
    );
  };

  const getVisibleReviews = () => {
    return [
      reviews[currentIndex],
      reviews[(currentIndex + 1) % reviews.length],
    ];
  };

  const toggleReadMore = (index) => {
    setExpandedIndex((prev) => (prev === index ? -1 : index));
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const visibleReviews = getVisibleReviews();

  return (
    <>
      <NavBar />
      <HomeMainComponent />
      <AboutandServices />
      <div className="serv-section">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-7 col-md-12 col-sm-6">
              <div className="text-center">
                <h3>Our Services</h3>
                <h4>Complete Medical Transportation</h4>
                <p>
                  At Connect Care Trans, we’re determined to make a difference
                  in non-medical transportation. Never again worry about getting
                  to your medical appointment on time. We've made it our job to
                  take care of these concerns. Our constant goal is to deliver
                  exceptional service.
                </p>
              </div>
              <div className="text-center">
                <Link to="/services" className="btn btn-primary rounded-1">
                  READ MORE
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="abt-section">
        <div className="container">
          <div className="row  justify-content-center">
            <div className="col-md-12 mb-3">
              <div className="text-center">
                <h4>Our Three-Step Process</h4>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 mb-3">
              <div className="cardbg">
                <div className="bagetext">
                  <span className="bg-primary rounded-1"> 1</span>
                </div>
                <div className="cardbgbody">
                  <div className="cardicon mb-3">
                    <img src={icon1} alt="" />
                  </div>
                  <div className="text-center">
                    <h5 className="mb-2">Schedule Your Ride</h5>
                    <p className="text-white">
                      Getting started is easy. Just call or use our convenient
                      online form to schedule your ride.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 mb-3">
              <div className="cardbg ">
                <div className="bagetext ">
                  <span className="bg-primary rounded-1"> 2</span>
                </div>
                <div className="cardbgbody">
                  <div className="cardicon mb-3">
                    <img src={icon2} alt="" />
                  </div>
                  <div className="text-center">
                    <h5 className="mb-2">We Handle the Details</h5>
                    <p className="text-white">
                      Once you've made your request, our team goes to work. We
                      carefully plan your trip. We also consider any special
                      needs you have. This ensures a smooth and comfortable trip
                      to the doctor, clinic, hospital, or other healthcare
                      location.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 mb-3">
              <div className="cardbg">
                <div className="bagetext">
                  <span className="bg-primary rounded-1"> 3</span>
                </div>
                <div className="cardbgbody">
                  <div className="cardicon mb-3">
                    <img src={icon3} alt="" />
                  </div>
                  <div className="text-center">
                    <h5 className="mb-2">Enjoy Peace of Mind</h5>
                    <p className="text-white">
                      On the day of your appointment, relax and let us take care
                      of everything. Our professional drivers will make sure you
                      arrive on time, every time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="serv-section mb-2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="mb-3 text-center">
                <h3>Key Service Differentiators</h3>
              </div>
            </div>
            <div className="col-auto">
              <a href="#">
                <img src={logo1} alt="Cert2" className="logo-img2" />
              </a>
            </div>
            <div className="col-auto">
              <a href="#">
                <img src={logo2} alt="Cert3" className="logo-img2" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="abt-section">
        <div className="container">
          <div className="row align-items-center justify-content-lg-around justify-content-md-between">
            <div
              className="col-lg-3 col-md-3 col-sm-12"
              style={{ textAlign: "start" }}
            >
              <div className="revitext">
                <h2>Google Reviews</h2>
                <h4>5.0</h4>
              </div>
              <div className="d-d-flex rev">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
              </div>
            </div>
            <div className="col-lg-8 col-md-7 col-sm-12">
              <div className="review-carousel">
                <button
                  className="arrow-left"
                  onClick={handlePrev}
                  style={{ fontSize: "15px" }}
                >
                  {"<"}
                </button>
                <div
                  className="items-container h-100 mx-5"
                  style={{ display: "contents" }}
                >
                  {visibleReviews.map((review, index) => (
                    <div
                      className="item review-card testbg text-center shadow"
                      key={index}
                    >
                      <div className="sidebar_user_profile mb-3">
                        <img
                          src={review.avatar}
                          alt={review.name}
                          style={{ width: "60px", height: "60px" }}
                        />
                      </div>
                      <div className="textem-text mb-3">
                        <h5>{review.name}</h5>
                      </div>
                      <div className="d-d-flex rev mb-3">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                      <div className="textem-text">
                        <p>
                          {expandedIndex === index
                            ? review.text
                            : truncateText(review.text, 35)}
                        </p>
                        {review.text.split(" ").length > 35 && (
                          <button
                            onClick={() => toggleReadMore(index)}
                            style={{
                              background: "none",
                              border: "none",
                              color: "#22a3b3",
                              cursor: "pointer",
                              padding: "0",
                            }}
                          >
                            {expandedIndex === index
                              ? "Read Less"
                              : "Read More"}
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  className="arrow-left"
                  onClick={handleNext}
                  style={{ fontSize: "15px" }}
                >
                  {">"}
                </button>
              </div>
            </div>
            {/* <div className="col-lg-8 col-md-7 col-sm-12">
              <div className="review-carousel">
                <button
                  className="arrow-left"
                  onClick={handlePrev}
                  style={{ fontSize: "15px" }}
                >
                  {"<"}
                </button>
                <div
                  className="items-container h-100 mx-5"
                  style={{ display: "contents" }}
                >
                  {visibleReviews.map((review, index) => (
                    <div
                      className="item review-card testbg text-center shadow"
                      key={index}
                    >
                      <div className="sidebar_user_profile mb-3">
                        <img
                          src={review.avatar}
                          alt={review.name}
                          style={{ width: "60px", height: "60px" }}
                        />
                      </div>
                      <div className="textem-text mb-3">
                        <h5>{review.name}</h5>
                      </div>
                      <div className="d-d-flex rev mb-3">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                      <div className="textem-text">
                        <p>{review.text}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  className="arrow-left"
                  onClick={handleNext}
                  style={{ fontSize: "15px" }}
                >
                  {">"}
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Home;
