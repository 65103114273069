/** @format */

import React from "react";
import aboutus from "../../assets/images/abtimg.png";
import { Link } from "react-router-dom";

const AboutandServices = ({ aboutUsPage }) => {
  return (
    <>
      <div className="abt-section">
        <div className="container">
          <div className="row align-items-center justify-content-around">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className=" ratio ratio-4x3 ">
                <img src={aboutus} className="shadow-sm" />
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="text-center">
                <h2>About Us</h2>
                <p>
                  Connect Care Trans is an important part of healthcare. We are
                  a locally owned Chicago Non-Emergency Medical Transportation
                  (NEMT) company. We take pride in our deep roots in this
                  community.
                </p>
                <p>
                  Our story began with a simple observation. Too many people
                  were missing vital medical appointments because of unreliable
                  transportation. Our founders drew from their extensive
                  experience as public chauffeurs. They saw an opportunity to
                  make a difference. They combined their expertise in
                  professional transportation with a passion for patient care to
                  create Connect Care Trans.
                </p>
              </div>
              <div className="text-center">
                <Link to="/about-us" className="btn btn-primary rounded-1">
                  READ MORE
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutandServices;
